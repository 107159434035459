<template>
  <div style="position: relative" :class="{webInline: webInline, 'without-border': withoutBorder}" tabindex="0" @focusout="focusOut">
    <div id="action-button" @click="dropDownIsOpen = !dropDownIsOpen">
      <slot name="action" />
      <img v-if="!hideChevron" :src="'https://images.nav.by/img/cdn/system/design_system/chevron-' + (dropDownIsOpen ? 'up' : 'down') + (light ? '-white' : '') +'.svg'">
    </div>

    <div
      class="dropdown-items"
      :style="{
        display: dropDownIsOpen ? 'flex' : 'none',
        width: widthItems || 'unset',
        right: rightPositionItems ? 0 : 'unset',
      }"
      @click="closeByClick ? dropDownIsOpen = false : ''"
    >
      <slot name="dropdown-items" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppDropDown',
  props: {
    webInline: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    rightPositionItems: {
      type: Boolean,
      default: false,
    },
    hideChevron: Boolean,
    withoutBorder: Boolean,
    closeByClick: Boolean,
    widthItems: String,
  },
  data() {
    return {
      dropDownIsOpen: false,
    };
  },
  methods: {
    focusOut() {
      if (this.closeByClick) {
        setTimeout(() => {
          this.dropDownIsOpen = false;
        }, 100)
      }
    }
  }
};
</script>

<style scoped>
#action-button {
  align-items: center;
  border: 1px solid var(--lightgrey-color);
  border-radius: var(--border-radius);
  cursor: pointer;
  height: 30px;
  display: flex;
  padding: 0 10px;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
}

.without-border #action-button {
  border: none;
}

.dropdown-items {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: absolute;
  background-color: var(--white-color);
  padding: 10px;
  border: 1px solid var(--lightgrey-color);
  border-radius: var(--border-radius);
  margin-top: 5px;
  z-index: 999;
  gap: 5px;
  width: 100%;
}

@media (min-width: 992px) {
  .webInline #action-button {
    display: none;
  }

  .webInline .dropdown-items {
    flex-direction: initial;
    align-items: center;
    position: relative;
    border: unset;
    background-color: unset;
    gap: 15px;
    display: flex !important;
    padding: 0;
    margin-top: 0;
    z-index: unset;
  }
}
</style>
