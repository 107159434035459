<template>
  <div class="container" :class="{big: big}">
    <div
      v-for="item in items"
      class="item"
      :class="{selected: item.key === selected}"
      @click="selectItem(item)"
    >
      <slot :name="'title(' + item.key + ')'" v-bind="item"><span v-html="item.text" /></slot>
    </div>
  </div>
</template>

<script>
/**
 * PROPS:
 *
 * items - массив для отображения. Параметры:
 * * key (required) - ключ
 * * text (required) - текст для отображения
 * * action - callback при выборе элемента. Пример: action: () => { this.getHistoryStatuses(); }
 *
 * selectedItem - ключ текущего выбранного элемента
 *
 * EMITS:
 * @update - передается ключ выбранного значения
 */

export default {
  name: 'AppSwitcher',
  props: {
    items: {
      type: Array,
      required: true,
    },
    selectedItem: {
      type: String,
    },
  },
  data() {
    return {
      selected: this.selectedItem ? this.selectedItem : (this.items && this.items.length > 0 ? this.items[0].key : null),
    };
  },

  watch: {
    selectedItem() {
      this.selected = this.selectedItem;
    },
  },

  methods: {
    selectItem(item) {
      if (item.action && typeof item.action === 'function') {
        item.action.call();
      }
      this.$emit('update', item.key);
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  border-bottom: 3px solid var(--lightgrey-color);
  width: 100%;
}

.container.big {
  border-bottom: 5px solid var(--lightgrey-color);
}

.item {
  flex: 1;
  color: #586EBF;
  text-align: center;
  font-family: var(--header-font);
  margin-bottom: -3px;
  cursor: pointer;
  padding-bottom: 5px;
  border-bottom: 3px solid transparent;
}

.container.big .item {
  margin-bottom: -5px;
  border-bottom: 5px solid transparent;
}

.item.selected {
  border-bottom: 3px solid #586EBF;
  cursor: default;
}

.container.big .item.selected {
  border-bottom: 5px solid #586EBF;
}
</style>