<template>
  <div class="main">
    <app-datepicker-toplabel v-model="date_loc" :toplabel="noLabel?'':('Дата'+(dopText!=''?' ':'')+dopText)" class="app-dttm-block-date" />
    <app-timepicker-toplabel v-model="time_loc" :toplabel="noLabel?'':('Время'+(dopText!=''?' ':'')+dopText)" class="app-dttm-block-time" />
    <img src="https://images.nav.by/img/cdn/admin/insert_dt_tm.svg" width="24" class="insert-icon"
         @click="insertFromClipboard()"
    >
  </div>
</template>
<script>
import moment from 'moment';

export default {
  name: 'AppDtTmBlock',
  props: {
    date: {
      default: moment(new Date()).format('YYYY-MM-DD'),
    },
    time: {
      default: moment((new Date()).getTime()).format('HH:mm:ss'),
    },
    dopText: {
      default: '',
    },
    noLabel: {
      default: false,
    },
  },
  data() {
    return {
      date_loc: this.date,
      time_loc: this.time,
    };
  },
  watch: {
    date_loc(newVal) {
      this.$emit('changeDt', newVal);
    },
    time_loc(newVal) {
      this.$emit('changeTm', newVal);
    },
    date(newVal) {
      this.date_loc = newVal;
    },
    time(newVal) {
      this.time_loc = newVal;
    },
  },
  mounted() {
    this.date_loc = this.date;
    this.time_loc = this.time;
  },
  methods: {
    async insertFromClipboard() {
      const text = await window.navigator.clipboard.readText();
      let match = text.match(/\d{4}-\d{2}-\d{2}/);
      if (match) {
        this.date_loc = match[0];
      }
      match = text.match(/(\d{2}).(\d{2}).(\d{4})/);
      if (match) {
        this.date_loc = `${match[3]}-${match[2]}-${match[1]}`;
      }
      match = text.match(/\d{2}:\d{2}:\d{2}/);
      if (match) {
        this.time_loc = match[0];
      }
    },
  },
};
</script>
<style scoped>

.insert-icon {
  margin-top: 0px;
}
.app-dttm-block-date {
    width:125px;
    margin-right: 1px;
}
.app-dttm-block-date :deep(.my-calendar){
    width:125px;
}
.app-dttm-block-time {
    width:110px;
    margin-right: 1px;
}
.app-dttm-block-time :deep(input[type="time"]){
    width:110px;
}
</style>
