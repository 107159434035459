<template>
  <app-input-text-with-datalist
    ref="inpComponent"
    v-model="valueLocal"
    :toplabel="toplabel"
    :placeholder="placeholder"
    :name="name"
    :small="small"
    :clearbutton="clearbutton"
    :read-only="readOnly"
    :style-input-text="styleInputText"
    :datalist="datalist"
    @blur="onBlur"
    @keydown.enter.prevent="enterAction()"
    @keydown="inputKeyDown"
    @keyup="inputKeyUp"
  />
</template>

<script>
export default {
  name: 'AppValidatedTextWithDatalist',
  props: {
    toplabel: {
      required: false,
      default: '',
    },
    validate: {
      required: false,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      required: false,
      type: String,
      default: '',
    },
    styleInputText: {
      required: false,
    },
    datalist: {
      default: null,
    },
    modalValue: {
      required: false,
      default: '',
    },
    value: {
      required: false,
      default: '',
    },
    name: {
      default: 'field',
    },
    clearbutton: {
      default: true,
    },
  },
  data() {
    return {
      newValForChange: '',
      down_count: 0,
      e_key: '',
      e_keyCode: 0,
      oldValue: '',
      valueLocal: '',
    };
  },

  watch: {
    modalValue(newVal) {
      this.change(newVal);
    },
    value(newVal) {
      this.change(newVal);
    },
  },

  mounted() {
    this.valueLocal = (this.modelValue !== null && this.modelValue !== undefined) ? this.modelValue : this.value;
  },
  methods: {

    change(val) {
      val = this.newValForChange === '' ? val : this.newValForChange;
      this.newValForChange = '';
      this.valueLocal = val;
      this.oldValue = String(val).substring(0, Math.max(0, val.length - 1));
      this.$emit('changeAction', val);
    },

    enterAction() {
      this.$emit('enter');
    },
    setSelelection(val) {
      this.$refs.inpComponent.setSelelection(val);
    },
    getSelStart() {
      return this.$refs.inpComponent.getSelStart();
    },
    getSelEnd() {
      return this.$refs.inpComponent.getSelEnd();
    },

    inputKeyDown(e) {
      if (e.key !== 'Shift' && e.key !== 'Alt' && e.key !== 'Control') {
        this.down_count++;
      }

      if (this.down_count === 1) {
        this.e_key = e.key;
        this.e_keyCode = e.keyCode;
        this.oldValue = this.getValue();
      }
      this.$emit('keydown', e);
    },
    inputKeyUp() {
      this.down_count = 0;
      // const obj = this.$el.children[1];
      let objValue = this.getValue();

      if (this.validate.toLowerCase().indexOf('email') > -1) {
        if (!((this.e_key >= '0' && this.e_key <= '9') || (this.e_keyCode >= 65 && this.e_keyCode <= 90) || this.e_key === '_' || this.e_key === '.' || this.e_key === '@' || this.e_key === '-' || this.e_key.length > 1)) {
          this.setValue(this.oldValue);
        }
      }
      if (this.validate.toLowerCase().indexOf('login') > -1) {
        if (objValue.length > 0 && objValue.match(/^[_0-9a-z]+$/) === null) {
          this.setValue(this.oldValue.match(/^([_0-9a-z]*)/)[0]);
          this.oldValue = this.valueLocal;
        }
      }
      if (this.validate.toLowerCase().indexOf('udigital') > -1) {
        if ((objValue.length > 0 && objValue.match(/^[0-9]+$/) === null)) {
          this.setValue(this.oldValue.match(/^([0-9]*)/)[0]);
          this.oldValue = this.valueLocal;
        }
      }
      if (this.validate.toLowerCase().indexOf('sdigital') > -1) {
        if ((objValue.length > 0 && objValue.match(/^[-0-9]+$/) === null)) {
          this.setValue(this.oldValue.match(/^(-?[0-9]*)/)[0]);
          this.oldValue = this.valueLocal;
        }
      }
      if (this.validate.toLowerCase().indexOf('coords') > -1) {
        objValue = objValue.replace(/[^\d,. ]/g, '');
        objValue = objValue.replaceAll(',', '.');
        const tmpArr = objValue.split(' ');
        let resStr = '';
        if (tmpArr.length > 1) {
          tmpArr.forEach((item, k) => {
            const match = item.match(/-?\d+\.?\d*/);
            if (match) {
              resStr += (resStr === '' ? '' : ' ') + parseFloat(match[0]).toString();
            } else if (k === 1) {
              resStr += ' ';
            }
          });
          if (objValue.substr(-1) === '.') {
            resStr += '.';
          }
          objValue = resStr;
          this.setValue(objValue);
        }

        this.valueLocal = objValue;
        if (this.getSelStart() === 1 && this.e_key === '.' && this.oldValue.indexOf('.') === -1) {
          this.setValue(`0.${this.oldValue}`);
          this.setSelelection(2);
        }
        if (objValue.length > 0) {
          if (
              objValue.match(/^[.0-9]+$/) === null
              && objValue.match(/^[.0-9]+\s$/) === null
              && objValue.match(/^[.0-9]+ [.0-9]+$/) === null
          ) {
            this.setValue(this.oldValue);
          }
        }
      }
      if (this.validate.toLowerCase().indexOf('ufloat') > -1) {
        if (this.getSelStart() === 1 && this.e_key === '.' && this.oldValue.indexOf('.') === -1) {
          this.setValue(`0.${this.oldValue}`);
          this.setSelelection(2);
        }
        if ((objValue.length > 0 && objValue.match(/^[.0-9]+$/) === null)) {
          this.setValue(this.oldValue);
        }
      }
      if (this.validate.toLowerCase().indexOf('sfloat') > -1) {
        if (this.getSelStart() === 1 && this.e_key === '.' && this.valueLocal.indexOf('.') === -1) {
          this.setValue(`0.${this.oldValue}`);
          this.setSelelection(2);
        }
        if ((objValue.length > 0 && objValue.match(/^[-.0-9]+$/) === null)) {
          this.setValue(this.oldValue);
        }
      }

      const reg = new RegExp(/max\(([0-9]*)\)/, 'gm');
      const res = reg.exec(this.validate);

      if (res != null && res.length === 2
          && (String(this.valueLocal).replace('-', '').length - (this.getSelEnd() - this.getSelStart()) > res[1] || (this.getSelStart() === 0 && this.getSelEnd() === 1 && this.oldValue.substr(0, 1) === '-'))
          && (this.e_key.length === 1 || this.e_key === 'Control' || this.e_key === 'Shift')
          && !(this.e_key === '-'
              && this.oldValue.indexOf('-') === -1
              && this.getSelStart() === 1
              && (this.validate.toLowerCase().indexOf('sfloat') > -1 || this.validate.toLowerCase().indexOf('sdigital') > -1))
      ) {
        this.setValue(this.oldValue.substr(0, parseInt(res[1], 10)));
        this.oldValue = this.oldValue.substr(0, parseInt(res[1], 10));
        this.newValForChange = this.oldValue;
      }
      this.oldValue = objValue;
    },

    onBlur() {
      if (this.valueLocal !== '') {
        const obj = this.$el.children[1];
        if (this.validate.toLowerCase().indexOf('email') > -1) {
          if (!this.valueLocal.match(/^[-A-Za-z0-9_.]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            obj.focus();
          }
        }
        if (this.validate.toLowerCase().indexOf('login') > -1) {
          if (!this.valueLocal.match(/^[-A-Za-z0-9_]+$/)) {
            obj.focus();
          }
        }

        if (this.validate.toLowerCase().indexOf('udigital') > -1) {
          if (!this.valueLocal.match(/^[0-9]+$/)) {
            obj.focus();
          }
        }
        if (this.validate.toLowerCase().indexOf('sdigital') > -1) {
          if (!this.valueLocal.match(/^-?[0-9]+$/)) {
            obj.focus();
          }
        }
        if (this.validate.toLowerCase().indexOf('coords') > -1) {
          if (this.valueLocal && String(this.valueLocal).substr(-1) === '.') {
            this.valueLocal += '0';
          }
          this.valueLocal = this.valueLocal.replaceAll(' .', ' 0.');
          if (!this.valueLocal.match(/^[0-9]+\.?[0-9]* [0-9]+\.?[0-9]*$/)) {
            obj.focus();
          }
        }
        if (this.validate.toLowerCase().indexOf('ufloat') > -1) {
          if (this.valueLocal && String(this.valueLocal).substr(-1) === '.') {
            this.valueLocal += '0';
          }
          if (this.valueLocal && !this.valueLocal.match(/^[0-9]+\.?[0-9]*$/)) {
            obj.focus();
          }
        }
        if (this.validate.toLowerCase().indexOf('sfloat') > -1) {
          if (this.valueLocal && String(this.valueLocal).substr(-1) === '.') {
            this.valueLocal += '0';
          }
          if (!this.valueLocal.match(/^-?[0-9]+\.?[0-9]*$/)) {
            obj.focus();
          }
        }

        let reg = new RegExp(/max\(([0-9]*)\)/, 'gm');
        let res = reg.exec(this.validate);
        if (res != null && res.length === 2 && this.valueLocal.length > res[1]) {
          obj.focus();
        }

        reg = new RegExp(/min\(([0-9]*)\)/, 'gm');
        res = reg.exec(this.validate);
        if (res != null && res.length === 2 && (this.valueLocal.length < res[1] || this.valueLocal.length === 0)) {
          obj.focus();
        }
      }
      this.$emit('blur', this.valueLocal);
    },

    setValue(newVal) {
      this.valueLocal = newVal;
      this.$refs.inpComponent.setValue(newVal);
    },

    getValue() {
      return this.$refs.inpComponent.getValue();
    },

    focus() {
      this.$el.children[1].focus();
    },
  },
};
</script>

<style scoped>

</style>
