<template>
  <div v-if="messages.length" class="messages-wrapper">
    <app-alert
      v-for="(message, index) of messages"
      :key="index"
      :message="message"
    />
  </div>
</template>

<script>
import AppAlert from './AppAlert.vue';

export default {
  name: 'AppAlerts',
  components: { AppAlert },
  computed: {
    messages() {
      return this.$store.state.alert.messages;
    },
  },
};
</script>

<style>
.messages-wrapper {
  z-index: 99999;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  right: 15px;
  bottom: 15px;
  gap: 15px;
}
</style>
