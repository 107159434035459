<template>
  <button class="button" :class="{disabled: disabled, 'small-button': small}" :disabled="disabled" @click="callback">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    disabled: Boolean,
    small: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    callback(e) {
      this.$emit('click-action', e);
    },
  },
};
</script>

<style scoped>
.button {
  background: white;
  border-radius: 10px;
  height: 40px;
  color: #1CA79F;
  padding: 0 20px;
  border: 1px solid #1CA79F;
  transition: all .1s ease-in;

}

.button:hover:not(.disabled) {
  background: #1CA79F;
  color: white;
}

.button.disabled {
  border: 1px solid #ADB5BD;
  color: #ADB5BD;
  cursor: no-drop;
}

.button.small-button {
  height: 30px;
}
</style>
