import Axios from 'axios/index';
import Qs from "qs";

const TOKEN = 'fc6b141d-456b-4511-a4c5-41c3ce3843c4';
const URL = 'https://api.nav.by/info/google_proxy.php';

export const searchAddress = (search) => {
  return Axios.get(URL, {
    params: {
      requestType: 'autocomplete',
      data: {input: search},
      token: TOKEN,
    },
    paramsSerializer: params => {
      return Qs.stringify(params);
    }
  })
}

export const getPlace = (placeId) => {
  return Axios.get(URL, {
    params: {
      requestType: 'get_place',
      data: {placeId: placeId},
      token: TOKEN,
    },
    paramsSerializer: params => {
      return Qs.stringify(params);
    }
  })
}