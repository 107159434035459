<template>
  <div class="message">
    <img :alt="message.type" :class="message.type">
    <div class="content-message">
      <div class="text">
        <p v-html="message.text"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppAlert',
  props: {
    message: {required: true, type: Object},
  },
  created() {
    setTimeout(() => {
      this.dismissToast();
    }, this.message.timeout || 3000);
  },
  methods: {
    dismissToast() {
      this.$store.commit('clearMessage', this.message.id);
    },
  },
};
</script>

<style scoped>
.success, .info, .error {
  color: var(--white);
}

.message img {
  width: 35px;
  height: 35px;
}

.success {
  content: url('https://images.nav.by/img/cdn/system/design_system/success_message.svg');
}

.error {
  content: url('https://images.nav.by/img/cdn/system/design_system/warning_message.svg');
}

.message {
  align-items: center;
  width: 300px;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  box-sizing: border-box;
  background-color: var(--white-color);
  justify-content: center;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 5px;
  box-shadow: 0 0 8px var(--grey-color);
}

.message .content-message, .message .header, .message .text {
  width: 100%;
  display: flex;
}

.message .text p {
  padding: 0;
  margin: 0;
  white-space: pre-line;
}

.message .content-message {
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
}

.message .header button:hover {
  background: var(--lightgrey-color);
}

.message .content-message {
  flex-grow: 1;
}

.small-header {
  text-align: left;
}
</style>
