<template>
  <div class="app-main-layout">
    <Header />
    <router-view />
  </div>
</template>

<script>
  import Header from '@/components/app/Header.vue'

  export default {
    name: 'main-layout',
    async mounted() {
      if (!this.$store.getters.getUserInfo) {
        await this.$store.dispatch('fetchInfo');
      }
    },
    computed: {
      error() {
        return this.$store.getters.getError
      }
    },
    watch: {
      error(error) {
        this.$error(error);
      }
    },
    components: {
      Header
    },
  }
</script>