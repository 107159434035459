<template>
  <div ref="date-picker" class="my-calendar">
    <DatePicker v-model="resultDate" :popover="{visibility: 'click'}" :update-on-input="updateOnInput" :masks="masks" :attributes="attributes" :disabled-dates="[disabledDate]">
      <template #default="{ inputValue, inputEvents, togglePopover }">
        <div style="position: relative; width: 100%">
          <app-input-text :value="inputValue" style="width: 100%" :without-border="withoutBorder" v-on="inputEvents" />
          <img src="https://images.nav.by/img/cdn/system/design_system/calendar.svg" class="icon-calendar" style="cursor: pointer" @click="togglePopover">
        </div>
      </template>
    </DatePicker>
  </div>
</template>

<script>
// Can use range and datetime
// Documentation here https://vcalendar.io/getting-started/installation.html

import {DatePicker} from 'v-calendar';
import 'v-calendar/style.css';
import moment from 'moment';

export default {
  name: 'AppDatepicker',
  components: {DatePicker},
  props: {
    modelValue: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    withoutBorder: Boolean,
    placeholder: {
      type: String,
      default: 'дд.мм.гггг',
    },
    updateOnInput: {
      type: Boolean,
      default: true,
    },
    invalid: Boolean,
  },
  data() {
    return {
      resultDate: this.modelValue === '0000-00-00' ? '' : this.modelValue,
      masks: {
        input: 'DD.MM.YYYY',
      },
      attributes: [{
        dot: true,
        dates: new Date(),
      }],
      disabledDate: null
    };
  },
  watch: {
    resultDate() {
      this.$emit('inputAction', this.resultDate ? moment(this.resultDate).format('YYYY-MM-DD') : null);
      this.$emit('update:modelValue', this.resultDate ? moment(this.resultDate).format('YYYY-MM-DD') : null);
      this.$emit('changeAction', this.resultDate ? moment(this.resultDate).format('YYYY-MM-DD') : null);
      this.$nextTick(() => {
        this.disabledDate = this.resultDate;
      });
    },
    modelValue() {
      if (moment(this.resultDate).format('YYYY-MM-DD') !== this.modelValue) {
        this.resultDate = this.modelValue;
      }
    },
  },
};
</script>

<style scoped>
.my-calendar {
  width: 135px;
  display: inline-block
}

.my-calendar :deep(.vc-popover-content-wrapper.is-interactive) {
  z-index: 9999 !important;
}

.my-calendar :deep(.vc-day-content.vc-disabled) {
  color: white;
}

.icon-calendar {
  position: absolute;
  right: 5px;
  top: 3px;
}
</style>
