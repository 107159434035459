export const sortString = (a, b) => {
  if (a === undefined || a === null) return -1;
  if (b === undefined || b === null) return 1;
  const fa = a.toLowerCase();
  const fb = b.toLowerCase();
  if (fa < fb) return -1;
  if (fa > fb) return 1;
  return 0;
};

export const sortNumber = (a, b) => {
  if (a === undefined || a === null) return -1;
  if (b === undefined || b === null) return 1;
  return a - b;
};

export const sortDate = (a, b) => {
  if (a === undefined || a === null) return -1;
  if (b === undefined || b === null) return 1;
  return new Date(a) - new Date(b);
};
