import {get} from '../utils/axios.plugin';

export default {
  state: {
    userInfo: null,
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    clearUserInfo(state) {
      state.userInfo = null;
    },
  },
  actions: {
    async fetchInfo({commit}) {
      await get('/lumen/auth/get_info', {})
        .then((response) => {
          if (response.data && response.data.exitCode === 0) {
            commit('setUserInfo', response.data.userInfo);
            return Promise.resolve();
          }
          return Promise.reject();
        });
    },
  },
  getters: {
    getUserInfo: (state) => state.userInfo,
  },
};
