import {createStore} from 'vuex'
import info from './info'
import auth from './auth'
import menu from './pages'
import users from './users'
import alert from './alert';

export default createStore({
  state: {
    backendUrl: "/backend/admin/vue_backend",
    error: null
  },
  mutations: {
    setError(state, error) {
      state.error = error
    },
    clearError(state) {
      state.error = null;
    }
  },
  actions: {},
  modules: {info, auth, menu, users, alert},
  getters: {
    getServerUrl: state => state.backendUrl,
    getError: state => state.error,
  }
})
