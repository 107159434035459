import Loader from './app/Loader.vue';
import AppBee from './app/app-bee.vue';
import NewsEditor from './app/NewsEditor.vue';
import appInputSearch from './app/app-input-search.vue';
import appInputText from './app/app-input-text.vue';
import appInputTextWithDatalist from './app/app-input-text-with-datalist.vue';
import appAutoresizeTextarea from './app/app-autoresize-textarea.vue';
import appButton from './app/app-button.vue';
import appButtonBg from './app/app-button-bg.vue';
import appCheckbox from './app/app-checkbox.vue';
import AppCheckboxDescription from './app/app-checkbox-description.vue';
import appDatepicker from './app/app-datepicker.vue';
import appRadio from './app/app-radio.vue';
import appSelect from './app/app-select.vue';
import appTimepicker from './app/app-timepicker.vue';
import appTimepickerToplabel from './app/app-timepicker-toplabel.vue';
import appSearchAddress from './app/app-search-address.vue';
import appSelectUsers from './app/app-select-users.vue';
import appValidatedTextWithDatalist from './app/app-validated-text-with-datalist.vue';
import appCountriesPhoneNumbers from './app/app-countries-phone-numbers.vue';
import appSelectToplabel from './app/app-select-toplabel.vue';
import appInputTextFilesToplabel from './app/app-input-text-files-toplabel.vue';
import appDatepickerToplabel from './app/app-datepicker-toplabel.vue';
import appAutoresizeTextareaToplabel from './app/app-autoresize-textarea-toplabel.vue';
import appSelectUsersToplabel from './app/app-select-users-toplabel.vue';
import appMessageBlock from './app/app-message-block.vue';
import appDtTmBlock from './app/app-dt-tm-block.vue';
import appSwitcher from './app/app-switcher.vue';
import appDropDown from './app/AppDropDown.vue';
import appDialog from './app/AppDialog.vue';
import appTable from './app/AppTable.vue';
import appPagination from './app/AppPagination.vue';
import confirmDialog from './app/ConfirmDialog.vue';

export default [
	Loader,
	AppBee,
	NewsEditor,
	appInputSearch,
	appInputText,
	appInputTextWithDatalist,
	appAutoresizeTextarea,
	appButton,
	appButtonBg,
	appCheckbox,
	AppCheckboxDescription,
	appDatepicker,
	appRadio,
	appSelect,
	appTimepicker,
	appTimepickerToplabel,
	appSearchAddress,
	appSelectUsers,
	appValidatedTextWithDatalist,
	appCountriesPhoneNumbers,
	appSelectToplabel,
	appInputTextFilesToplabel,
	appDatepickerToplabel,
	appAutoresizeTextareaToplabel,
	appSelectUsersToplabel,
	appMessageBlock,
	appDtTmBlock,
	appSwitcher,
	appDropDown,
	appDialog,
	appTable,
	appPagination,
	confirmDialog,
];
