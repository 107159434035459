import store from '../store';

export default {
  showSuccessMessage(text, timeout) {
    store.commit('showSuccessMessage', {
      text,
      timeout: timeout || 2000,
    });
  },
  showErrorMessage(text, timeout) {
    store.commit('showErrorMessage', {
      text,
      timeout: timeout || 2000,
    });
  },
};
