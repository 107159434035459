import {get} from '../utils/axios.plugin';

export default {
  state: {
    users: null,
    roles: null,
  },
  mutations: {
    setUsersInfo(state, data) {
      state.users = data.users;
      state.roles = data.roles;
    },
  },
  actions: {
    async fetchUsers({commit}) {
      await get('/lumen/menu/get_user_list_and_role', {})
        .then((response) => {
          if (response.data && response.data.exitCode === 0) {
            commit('setUsersInfo', response.data)
          }
        });
    },
  },
  getters: {
    getUsers: (state) => state.users,
    getRoles: (state) => state.roles,
  },
};
