<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
    <app-alerts />
  </div>
</template>

<script>
import EmptyLayout from './layouts/EmptyLayout.vue';
import MainLayout from './layouts/MainLayout.vue';
import AppAlerts from './components/app/alert/AppAlerts.vue';

export default {
  components: {
    AppAlerts,
    EmptyLayout,
    MainLayout,
  },
  computed: {
    layout() {
      return `${this.$route.meta.layout || 'empty'}-layout`;
    },
  },
};
</script>

<style>
  @import './assets/index.css';
  @import "./components/app/multipleselect/vue-multiselect.min.css";
  @import 'viewerjs/dist/viewer.css';
</style>
