<template>
  <div class="checkbox-description-min-size">
    <div :class="{'checkbox-description-main':description!=''}">
      <app-checkbox
        :checked="checkedLocal"
        :disabled="disabled"
        @click="$event.stopPropagation();"
        @changeAction="clickChange($event)"
      >
        <div style="display: flex; flex-direction: column;">
          <slot />
          <div v-if="description!=''" class="bottom-description-component">{{ description }}</div>
        </div>
      </app-checkbox>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppCheckboxDescription',
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {

    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      checkedLocal: this.checked,
    };
  },
  watch: {
    checked(newVal) {
      this.checkedLocal = newVal;
    },
  },
  methods: {
    clickChange(event) {
      this.checkedLocal = event;
      this.$emit('changeAction', event);
    },
  },
};
</script>

<style scoped>
.checkbox-description-min-size{
  max-height: 24px;
}
.checkbox-description-main .bottom-description-component{
  white-space: nowrap;
  color: #95A0AA;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 130%;
}
.checkbox-description-main{
  display: inline-block;
  max-height: 26px;
}
.checkbox-description-main :deep(label) {
  position: relative;
  top:-6px;
  width: 100%;
}

div {
  display: inline-flex;
}

@-moz-document url-prefix() {
  .p-1 th div {
    position: relative;
    top: 5px;
  }
}

</style>
