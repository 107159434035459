<template>
  <div style="z-index: 10000; position: fixed; width: 80px; height: 80px; left: calc(50% - 25px); top: calc(50% - 25px); background-color: white; border-radius: 50%; padding: 10px !important; box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);">
    <img src="https://images.nav.by/img/cdn/system/design_system/main-loader.gif?3">
  </div>
</template>

<script>
export default {
  name: 'Loader',
};
</script>
