<template>
  <vue-tel-input
      v-model="val"
      mode="international"
      default-country="BY"
      :validCharactersOnly="true"
      :only-countries="onlyCountries"
      :input-options="{placeholder: 'Введите номер телефона'}"
      style="width: 100%"
      @blur="blur"
      @validate="update"
  />
</template>

<script>
import {VueTelInput} from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

export default {
  name: 'AppCountriesPhoneNumbers',
  components: {VueTelInput},
  props: {
    value: String,
  },
  data() {
    return {
      val: this.value,
      countryCode: 'BY',
      onlyCountries: ['BY', 'RU', 'PL', 'LT', 'LV', 'KZ', 'UA'],
    };
  },

  watch: {
    value() {
      this.val = this.value;
    },
  },

  methods: {
    update(data) {
      this.val = data.number;
      this.$emit('update', data);
    },
    blur() {
      this.$emit('update', {valid: true, number: this.val});
    },
  },
};
</script>

<style scoped>

</style>
