import {get} from '../utils/axios.plugin';

export default {
  state: {
    pages: null,
    navPages: null,
  },
  mutations: {
    setPagesInfo(state, data) {
      state.pages = data.pages;
      state.navPages = data.navPages;
    },
    clearPagesInfo(state) {
      state.pages = null;
      state.navPages = null;
    },
  },
  actions: {
    async fetchPages({commit}) {
      await get('/lumen/menu/init', {})
        .then((response) => {
          if (response.data && response.data.exitCode === 0) {
            commit('setPagesInfo', response.data)
          }
        });
    },
  },
  getters: {
    getPages: (state) => state.pages,
    getNavPages: (state) => state.navPages,
  },
};
