<template>
  <div v-if="show && modal" class="dialog">
    <app-dialog-container
      :title="title"
      :height="height"
      :width="width"
      :overflow-auto="overflowAuto"
      :modal="modal"
      :without-padding="withoutPadding"
      @close="hideDialog"
    >
      <template v-slot:header>
        <slot name="header" />
      </template>

      <slot />

      <template v-slot:footer>
        <slot name="footer" />
      </template>
    </app-dialog-container>
  </div>

  <app-dialog-container
    v-else-if="show && !modal"
    :title="title"
    :height="height"
    :width="width"
    :overflow-auto="overflowAuto"
    :modal="modal"
    :left="left"
    :right="right"
    :top="top"
    :bottom="bottom"
    @close="hideDialog"
  >
    <slot />
    <template v-slot:footer>
      <slot name="footer" />
    </template>
  </app-dialog-container>
</template>

<script>
/**
 * PROPS:
 * show - true/false для отображения диалога
 * title - тайтл
 * height - если нужно указать фикс. высоту
 * width - ширина диалого, максимальная ширина 95% (по умолчанию 500)
 * overflow-auto - если есть скролл
 * modal - false если хотим перетягивать диалог, по умолчаниб true
 * left - можем указать расположение диалога, по умолчанию по центру
 * right - можем указать расположение диалога, по умолчанию по центру
 * top - можем указать расположение диалога, по умолчанию по центру
 * bottom - можем указать расположение диалога, по умолчанию по центру
 * without-padding - убираем отступы
 *
 * SLOTS:
 * footer - обычно сюда добавляем кнопки снизу диалога
 * header - можем изменять хедер
 *
 * EMITS:
 * @close - событие нажатия на крестик
 */

import AppDialogContainer from './dialog/AppDialogContainer.vue';

export default {
  name: 'AppDialog',
  components: {AppDialogContainer},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: null,
    },
    width: {
      type: [Number, String],
      default: 500,
    },
    overflowAuto: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: true,
    },
    left: {
      type: String,
      default: null,
    },
    right: {
      type: String,
      default: null,
    },
    top: {
      type: String,
      default: null,
    },
    bottom: {
      type: String,
      default: null,
    },
    withoutPadding: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hideDialog() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.dialog {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1039;
}
</style>
