<template>
  <div>
    <viewer ref="viewer" :images="imagesAvatar" :options="options">
      <img v-for="src in imagesAvatar" :key="src" ref="imgShow3" :src="src" class="viewerClickAvatar">
    </viewer>
    <div class="my-navbar">
      <div style="display: flex; align-items: center">
        <div style="margin: 0 10px">
          <router-link :class="{disabledHomeButton: $route.path === '/'}" title="На главную" to="/">
            <img src="https://images.nav.by/img/cdn/admin/home-icon.svg">
          </router-link>
        </div>

        <div v-if="usersRoles.length > 0" @click="showDialogPhoneNumbers = true">
          <a title="Телефонная будка">
            <img
              id="employeePhoneNumbers" src="https://images.nav.by/img/cdn/admin/phone-home.svg"
              style="cursor: pointer;"
            >
          </a>
        </div>
      </div>

      <app-drop-down web-inline light close-by-click style="width: 100%">
        <template #action>
          <span style="color: var(--white-color)">Быстрый доступ</span>
        </template>

        <template #dropdown-items>
          <div class="my-navbar-nav">
            <router-link
              v-for="(page, index) in navPages" :key="index"
              :to="page.url.replace('admin/', 'ui/').replace('.php', '')+queryStr" exact
            >
              <span class="title">{{ page.name }}</span>
            </router-link>
          </div>
        </template>
      </app-drop-down>

      <div style="display: flex; align-items: center">
        <div style="margin-right: 10px">
          <a @click="logout">
            <img src="https://images.nav.by/img/cdn/admin/log-out.svg">
          </a>
        </div>

        <div style="z-index: 1; overflow: hidden; margin: 2px 0">
          <div class="my-avatar" style="width: 40px; height: 40px">
            <img :src="vuexUserInfo.photoUrl+'&max_size=80'" @click="avatarClick(vuexUserInfo.photoUrl)">
          </div>
          <span
            v-if="vuexUserInfo.ordinal_number > 0"
            title="Порядковый номер"
            style="font-weight: bold; z-index: 2; position: absolute; margin: -20px 25px; font-size: 13px; color: black; width: 20px; text-align: center; background-color: antiquewhite; border-radius: 11px; opacity: 0.9;"
          >{{ vuexUserInfo.ordinal_number }}</span>
        </div>
      </div>
    </div>

    <app-dialog :show="showDialogPhoneNumbers" title="Телефонная будка" width="900" @close="showDialogPhoneNumbers = false">
      <template #header>
        <app-input-search
          v-model="searchPhonesInfo"
          set-focus
          placeholder="Поиск"
          autocomplete="off"
        />
      </template>

      <div style="height: 500px; overflow: auto">
        <table id="tablePhoneNumbersUsers" class="catTable catHoverTable" style="width: 100%;">
          <thead>
            <tr>
              <th>Фото</th>
              <th>Сотрудник</th>
              <th>Добавочный</th>
              <th>Мобильный</th>
              <th>Почта</th>
              <th>Skype</th>
            </tr>
          </thead>
          <tbody v-for="(role, roleId) in filteredUsers" :key="roleId">
            <tr>
              <th colspan="6">{{ role.name + (role.study ? ' (каб. ' + role.study + ')' : '') }}</th>
            </tr>
            <tr v-for="(user, userId) in role.users" :key="userId">
              <td>
                <div class="my-avatar" style="width: 40px; height: 40px; margin: 5px">
                  <img :src="user.photoUrl+'&max_size=80'" @click="avatarClick(user.photoUrl)">
                </div>
              </td>
              <td>
                {{ user.name }}
                <div>{{ user.regionText }}</div>
              </td>
              <td>{{ user.dop_phone }}</td>
              <td>
                <a
                  v-for="(phone, index) in user.phone" :key="index" :href="'tel:' + phone"
                  style="color: black; font-weight: normal"
                >{{ phone }}</a>
              </td>
              <td>
                <a :href="'mailto:' + user.email" style="color: black; font-weight: normal">{{ user.email }}</a>
                <div v-if="user.user_id === vuexUserInfo.user_id"><a @click="showDialogSignatureMail = true">подпись</a></div>
              </td>
              <td>
                <a
                  :href="'skype:' + user.skype + '?chat'"
                  style="color: black; font-weight: normal"
                >{{ user.skype }}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </app-dialog>

    <app-dialog
      :show="showDialogSignatureMail" title="Настройка подписи" width="900" overflow-auto
      @close="showDialogSignatureMail = false"
    >
      <div style="padding: 5px">
        <span class="label">ФИО</span>
        <app-input-text v-model="vuexUserInfo.name" placeholder="ФИО" style="width: 100%" />
      </div>
      <div style="padding: 5px">
        <span class="label">Мобильный телефон</span>
        <app-input-text v-model="vuexUserInfo.phone" placeholder="Мобильный телефон" style="width: 100%" />
      </div>
      <div style="padding: 5px">
        <span class="label">Добавочный телефон</span>
        <app-input-text v-model="vuexUserInfo.dopPhone" placeholder="Добавочный телефон" style="width: 100%" />
      </div>
      <div style="padding: 5px">
        <span class="label">Email</span>
        <app-input-text v-model="vuexUserInfo.email" placeholder="Email" style="width: 100%" />
      </div>
      <div style="padding: 5px">
        <span class="label">Skype</span>
        <app-input-text v-model="vuexUserInfo.skype" placeholder="Skype" style="width: 100%" />
      </div>
      <div style="padding: 5px">
        <span class="label">Должность</span>
        <app-input-text v-model="vuexUserInfo.position" placeholder="Должность" style="width: 100%" />
      </div>
      <div style="padding: 5px">
        <span class="label">HTML CODE</span>
        <app-autoresize-textarea
          v-model="signatureMail" placeholder="HTML CODE" style="width: 100%; height: 300px"
          disabled
        />
      </div>
      <div id="signature-mail-result" style="margin-top: 40px;margin-left:80px;" v-html="signatureMail" />
    </app-dialog>
  </div>
</template>

<script>
import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, onMessage} from 'firebase/messaging';

export default {
  name: 'Header',
  data() {
    return {
      messaging: null,
      searchPhonesInfo: '',
      isPhotoPhonesInfo: true,
      navPages: [],
      usersRoles: [],
      showDialogPhoneNumbers: false,
      showDialogSignatureMail: false,
      isMobileVersion: false,
      queryStr: '',
      imagesAvatar: [],
      options: {navbar: true, title: false, toolbar: true, tooltip: false, initialViewIndex: 3},
    };
  },

  computed: {
    filteredUsers() {
      const filtered = [];
      if (this.searchPhonesInfo.toLowerCase() === '') {
        return this.usersRoles;
      }
      this.usersRoles.map((role) => {
        if (role.name.toLowerCase().includes(this.searchPhonesInfo.toLowerCase())) {
          filtered.push(role);
          return;
        }
        const users = role.users.filter((user) => (user.name && user.name.toLowerCase().includes(this.searchPhonesInfo.toLowerCase())) || (user.dop_phone && user.dop_phone.toLowerCase().includes(this.searchPhonesInfo.toLowerCase())) || (user.email && user.email.toLowerCase().includes(this.searchPhonesInfo.toLowerCase())));
        if (users.length > 0) {
          filtered.push({name: role.name, users, study: role.study});
        }
      });
      return filtered;
    },

    signatureMail() {
      return `${'<div style="width:600px;padding:20px;">'
          + '<div style="width:100%">'
          + '<span style="display: inline-flex;align-items: center;justify-content: center;vertical-align: middle;flex-shrink: 0;width: 130px;height: 130px;font-size: inherit;font-weight: 400;line-height: 1;max-width: 100%;max-height: auto;text-align: center;overflow: visible;position: relative;border-radius: 50%!important;transition: color .15s ease-in-out,background-color .15s ease-in-out,box-shadow .15s ease-in-out;">'
          + '<img  style="width: 100%;height: 100%;max-height: auto;border-radius: inherit;-o-object-fit: cover;object-fit: cover;" src="'}${this.vuexUserInfo.photoUrl}&max_size=130" alt="avatar">`
          + '</span>'
          + '<div style="vertical-align: top;display:inline-block;width:410px;margin-left:15px;">'
          + '<span style="width:100%;font-family: Roboto,sans-serif;font-style: normal;font-weight: 400;font-size: 12px;line-height: 14px;align-items: center;color: #A2ABBB;display: block;">С уважением, </span>'
          + `<span style="display: block;font-family: Roboto,sans-serif;font-style: normal;font-weight: 400;font-size: 14px;line-height: 16px;margin: 5px 0px">${this.vuexUserInfo.name ? this.vuexUserInfo.name : ''}</span>`
          + `<span style="display: block;font-family: Roboto,sans-serif;font-weight: 400;font-size: 12px;line-height: 14px;">${this.vuexUserInfo.position ? this.vuexUserInfo.position : ''} ЧУП "Белтрансспутник"</span>`
          + '<table style="margin-top:11px;width:100%;font-family: Roboto,sans-serif;font-style: normal;font-weight: 400;font-size: 12px;line-height: 14px;">'
          + '<tr>'
          + '<td style="color: #A2ABBB;">e-mail</td>'
          + `<td><a href="mailto:${this.vuexUserInfo.email ? this.vuexUserInfo.email : ''}" target="_blank"  style="color:#2F5FAC;text-decoration:none;font-weight: 600;">${this.vuexUserInfo.email ? this.vuexUserInfo.email : ''}</a></td>`
          + '<td style="width: 120px;"><a href="https://beltranssat.by" target="_blank"  style="color:#2F5FAC;text-decoration:none;font-weight: 600;">www.beltranssat.by</a></td>'
          + `</tr>${
            this.vuexUserInfo.skype ? `${'<tr>'
                  + '<td style="color: #A2ABBB;">Skype</td>'
                  + '<td><a href="skype:'}${this.vuexUserInfo.skype}" style="color:#2F5FAC;text-decoration:none;font-weight: 600;">${this.vuexUserInfo.skype ? this.vuexUserInfo.skype : ''}</a></td>`
                  + '<td></td>'
                  + '</tr>' : ''
          }<tr>`
          + '<td style="color: #A2ABBB;">Гор. тел.</td>'
          + `<td style="font-family: Roboto,sans-serif;font-style: normal;font-weight: 400;font-size: 12px;line-height: 14px;">+375 17 375 07 04 (вн. ${this.vuexUserInfo.dopPhone ? this.vuexUserInfo.dopPhone : ''})</td>`
          + `<td  style="font-family: Roboto,sans-serif;font-style: normal;font-weight: 400;font-size: 12px;line-height: 14px;vertical-align:top;" ${this.vuexUserInfo.phone ? 'rowspan=2' : ''}>220005, г. Минск,<br>ул. Платонова, 12Б</td>`
          + `</tr>${
            this.vuexUserInfo.phone ? `${'<tr>'
                  + '<td style="color: #A2ABBB;">Моб. тел.</td>'
                  + '<td style="font-family: Roboto,sans-serif;font-style: normal;font-weight: 400;font-size: 12px;line-height: 14px;">'}${this.vuexUserInfo.phone}</td>`
                  + '</tr>' : ''
          }</table>`
          + '</div>'
          + '</div>'
          + '<div style="width:100%;text-align:center">'
          + '<img src="https://images.nav.by/img/cdn/system/logo_bts.jpg" style="height:38px; margin-bottom:10px;">'
          + '</div>'
          + '<div style="width:600px;font-family: \'Inter\';font-style: normal;font-weight: 400;font-size: 10px;line-height: 12px;color: #A2ABBB;">Это электронное письмо и приложения к нему содержат конфиденциальную информацию, предназначенную исключительно для использования получателем. Любой отзыв, ссылка или распространение другими способами или переадресация без разрешения отправителя запрещена.'
          + 'Если вы не предполагаемый получатель, пожалуйста, свяжитесь с отправителем и удалите все копии и приложения.<br><br>'
          + 'This email and its attachments contain confidential information for the sole use of the intended recipient. Any review, linking or other distribution or forwarding without express permission is strictly prohibited. If you are not the intended recipient, please contact the sender and delete all copies and attachments.'
          + '</div>'
          + '<div style="width:100%;text-align:center;">'
          + '<a style="font-family: Roboto,sans-serif;font-style: normal;font-weight: 400;font-size: 12px;line-height: 14px;text-decoration-line: underline;color: #2F5FAC;" target="_fbbts" href="https://www.facebook.com/BelTransSat/">Facebook</a>'
          + '<a style="font-family: Roboto,sans-serif;font-style: normal;font-weight: 400;font-size: 12px;line-height: 14px;text-decoration-line: underline;color: #2F5FAC;margin: 0px 10px 0px 10px;" target="_insbts" href="https://www.instagram.com/beltranssat/">Instagram</a>'
          + '<a style="font-family: Roboto,sans-serif;font-style: normal;font-weight: 400;font-size: 12px;line-height: 14px;text-decoration-line: underline;color: #2F5FAC;"  target="_youtbts" href="https://www.youtube.com/channel/UCJtohKjBJM3XKMM09RK0H8A">Youtube</a>'
          + '</div>'
          + '</div>';
    },

    sesUserId() {
      return this.$store.getters.getUserInfo ? this.$store.getters.getUserInfo.user_id : null;
    },

    sesRoleId() {
      return this.$store.getters.getUserInfo ? this.$store.getters.getUserInfo.role_id : null;
    },

    /**
     * ADM-1227
     * Добавляю этот объект на замену прошлому userInfo из data. На данный момент остается проблема, что loadUserInfo не вызывается после захода в логин.
     * Причина - vuex.userInfo заполняется сразу после авторизации, watch.sesUserId начинает наблюдение только после первой инициализации computed.sesUserId(),
     * sesUserId инициализируется нормально т.к. vuex.userInfo заполнен и watch больше не триггерится.
     * При обновлении страницы данная логика работает, лишь потому что Header монтируется раньше, чем MainLayot, а именно в MainLayout
     * происходит инициализация vuex.userInfo
     * TODO Стоит переработать логику работы компонента
     */
    vuexUserInfo() {
      return this.$store.getters.getUserInfo || {};
    },
  },

  watch: {
    sesUserId() {
      this.loadUserInfo();
    },
  },

  mounted() {
    const self = this;
    self.$nextTick(() => {
      window.addEventListener('resize', () => {
        self.isMobileVersion = window.innerWidth < 992;
      });
    });
    self.isMobileVersion = window.innerWidth < 992;
    this.checkParams();
  },

  beforeUnmount() {
    window.removeEventListener('resize', () => {
    });
  },
  created() {
    this.loadNavPages();
    this.loadUsers();
    if (this.sesUserId) {
      this.loadUserInfo();
    }
  },

  methods: {
    checkParams() {
      this.queryStr = '';
      const query = this.$route.query;
      const params = Object.keys(query);
      params.forEach((item) => {
        if (item.indexOf('imei') > -1) {
          this.queryStr += `${this.queryStr === '' ? '' : '&'}imei=${query[item]}`;
        } else {
          this.queryStr += `${this.queryStr === '' ? '' : '&'}${item}=${query[item]}`;
        }
      });
      this.queryStr = `${this.queryStr === '' ? '' : '?'}${this.queryStr}`;
    },

    async logout() {
      await this.$store.dispatch('logout');
      this.$router.push('/ui/login');
      localStorage.setItem('firebaseMessagingToken', null);
    },

    async loadNavPages() {
      if (!this.$store.getters.getPages) {
        await this.$store.dispatch('fetchPages');
      }
      this.navPages = this.$store.getters.getNavPages;
    },

    async loadUserInfo() {
      if (!this.$store.getters.getUserInfo) return;
      this.initFirebase();
    },

    async loadUsers() {
      if (!this.$store.getters.getUsers) {
        await this.$store.dispatch('fetchUsers');
      }
      const roles = this.$store.getters.getRoles || [];
      const users = this.$store.getters.getUsers || [];
      const usersRoles = [];
      roles.forEach((role) => {
        const roleInfo = {name: role.name, users: [], study: role.study};
        users.forEach((user) => {
          if (role.role_id === user.role_id) {
            roleInfo.users.push(user);
          }
        });
        if (roleInfo.users.length > 0) {
          usersRoles.push(roleInfo);
        }
      });
      this.usersRoles = usersRoles;
    },

    initFirebase() {
      const app = initializeApp({
        apiKey: 'AIzaSyDKM5s9wk9HrsL9pxOItvzYPUiWnJkzIS0',
        authDomain: 'nav-by.firebaseapp.com',
        projectId: 'nav-by',
        storageBucket: 'nav-by.appspot.com',
        messagingSenderId: '878369688360',
        appId: '1:878369688360:web:d5eec58723dc99d6b2de1d',
        measurementId: 'G-MNWE0RKT48',
      });

      const messaging = getMessaging(app);

      onMessage(messaging, (payload) => {
        const notification = payload.data;
        setTimeout(() => {
          if (localStorage.getItem('lastAdmNotificationId') !== notification.id) {
            localStorage.setItem('lastAdmNotificationId', notification.id);
            navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then((registration) => {
              registration.showNotification(notification.title, notification);
            });
          }
        }, Math.random() * 1000);
      });

      getToken(messaging, {vapidKey: 'BIMRUsasgKoRhvus1Eh0s-DYYUJF_iJmsbbHJqOpNOaFzjJaNiMahkkOfClXdjiBKDbJi4I39lOGmWf5Fg4uOpU'}).then((currentToken) => {
        if (currentToken) {
          if (localStorage.getItem('firebaseMessagingToken') !== currentToken) {
            this.$post('/lumen/auth/save-fcm-token', {fcmToken: currentToken}).then((response) => {
              if (response.data.exitCode === 0) {
                localStorage.setItem('firebaseMessagingToken', currentToken);
              }
            });
          }
        }
      }).catch((err) => {
        console.warn('Не удалось получить разрешение на показ уведомлений. ', err);
      });
    },
    avatarClick(src) {
      this.imagesAvatar = [src];
      setTimeout(() => {
        document.querySelector('.viewerClickAvatar').click();
      }, 100);
    },
  },
};
</script>

<style scoped>
.my-navbar {
  padding: 0 5px;
  background-color: #586EBF;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.router-link-active span.title {
  color: #586EBF;
}

#nav-collapse {
  width: calc(100% - 170px);
}

.my-navbar-nav {
  display: flex;
  width: 100%;
}

.my-navbar-nav a {
  min-height: 26px;
  float: left;
  font-size: 14px;
  height: 27px;
  flex-basis: 0;
  flex-grow: 1;
  margin-right: 1%;
  display: inline-block;
  color: #FFFFFF;
  user-select: none;
  line-height: 24px;
  padding: 1px 4px;
  outline: none;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: initial;
  text-decoration: none;
  background-color: #697CC5;
}

.my-navbar-nav img {
  margin-right: 5px;
  float: left;
  width: 20px;
  margin-top: 3px;
}

.my-navbar-nav span {
  margin-top: 2px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.my-navbar-nav .router-link-active {
  background-color: rgba(255, 255, 255, 0.88);
}

.disabledHomeButton {
  pointer-events: none;
  opacity: 0.2
}

#tablePhoneNumbersUsers thead tr:nth-child(1) th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.viewerClickAvatar {
  display: none;
}

.my-avatar {
  cursor: pointer;
}

@media (max-width: 992px) {
  .my-navbar-nav {
    flex-direction: column;
  }
}
</style>
