export default {
  state: {
    messages: [],
  },
  mutations: {
    showErrorMessage(state, data) {
      state.messages.push({
        id: Math.random(),
        type: 'error',
        text: data.text,
        timeout: data.timeout,
      });
    },
    showSuccessMessage(state, data) {
      state.messages.push({
        id: Math.random(),
        type: 'success',
        text: data.text,
        timeout: data.timeout,
      });
    },
    clearMessage(state, id) {
      const index = state.messages.findIndex((message) => message.id === id);
      state.messages.splice(index, 1);
    },
  },
};
