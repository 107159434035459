<template>
  <div :class="toplabel==''?'main-div-app-select-inline':'main-div-app-select'">
    <div v-if="toplabel!=''" class="toplabel">{{ toplabel }}</div>
    <textarea
      :placeholder="placeholder"
      :value="localValue"
      :style="styleTextarea"
      :class="dragging ? 'drop-file' : ''"
      @drop.prevent="$emit('dropFile', $event); dragging = false"
      @dragleave="dragging = false"
      @dragover="dragging = true"
      @input="update($event)"
      @keydown.enter.prevent="enterAction"
      @change="change($event)"
    />
  </div>
</template>

<script>
export default {
  name: 'AppAutoresizeTextareaToplabel',
  props: {
    toplabel: {
      type: String,
      default: '',
    },
    modelValue: String,
    placeholder: String,
    styleTextarea: Object,
  },

  data() {
    return {
      dragging: false,
      localValue: this.modelValue,
    };
  },

  watch: {
    modelValue() {
      this.localValue = this.modelValue;
    },
  },
  methods: {
    resize(e) {
      e.target.style.height = this.styleTextarea && this.styleTextarea['min-height'] ? this.styleTextarea['min-height'] : 'auto';
      e.target.style.height = `${e.target.scrollHeight + 2}px`;
    },

    enterAction(e) {
      const keyCode = e.which || e.keyCode;
      if (keyCode === 13 && !e.shiftKey) {
        this.$emit('enter');
      }
    },

    getValue() {
      return this.localValue;
    },

    update(event) {
      this.$emit('update:modelValue',event.target.value);
      this.$emit('inputAction', event.target.value);
      this.resize(event);
    },

    change(event) {
      this.localValue = event.target.value;
      this.$emit('changeAction', event.target.value);
    },
  },
};
</script>

<style scoped>
  .textarea{
    padding: 0px;
    margin-top: -12px;
  }
  textarea {
    padding: 5px 10px;
    width:100%;
    box-sizing: border-box;
    font-size: 14px;
    resize: none;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }

  textarea::placeholder {
    color: #ADB5BD;
    font-weight: 400;
  }

  textarea:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0, 125, 255, 25%);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }

  .drop-file {
    border: #999 4px dashed !important;
  }

  @media (max-width: 992px) {
    textarea {
      font-size: 16px;
    }
  }

  .toplabel {
    background-color: white;
    width: auto !important;
    display: inline;
    position: relative;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #95A0AA;
    z-index: 6;
    margin-left: 5px;
    margin-top: -7px;
    padding: 0px 3px;
    height: 10px;
    top:7px;
  }

</style>
