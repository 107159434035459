<template>
  <app-dialog :title="title" :show="showDialog" without-padding @close="cancel">
    <p>{{ message }}</p>
    <div class="buttons">
      <app-button-bg v-if="okButton" style="min-width: 100px" @click-action="confirm">{{ okButton }}</app-button-bg>
      <app-button v-if="cancelButton" style="min-width: 100px" minor @click-action="cancel">{{ cancelButton }}</app-button>
    </div>
  </app-dialog>
</template>

<script>

export default {
  name: 'ConfirmDialog',
  data: () => ({
    title: '',
    message: '',
    okButton: '',
    cancelButton: null,
    showDialog: false,
    resolvePromise: null,
    rejectPromise: null,
  }),

  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.cancel();
      }
    },
  },

  methods: {
    show(title, message, textOkButton, textCancelButton) {
      this.title = title;
      this.message = message;
      this.okButton = textOkButton;

      if (textCancelButton) {
        this.cancelButton = textCancelButton;
      }

      this.showDialog = true;

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    confirm() {
      this.showDialog = false;
      this.resolvePromise(true);
    },

    cancel() {
      this.showDialog = false;
      this.resolvePromise(false);
    },
  },
};
</script>

<style scoped>
.buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 30px;
}

p {
  padding: 0 20px;
  text-align: center;
  font-weight: normal;
  font-size: 14px;
}
</style>
