import moment from 'moment'

const errors = [];
const titles = {};
const refs = {};
export default {
	run(vm, data, rulesInput, border = false, alert = false) {
		const self = this;
		const keys = Object.keys(rulesInput);
		let isArray = true;
		keys.forEach((item) => {
			if (isNaN(item)) {
				isArray = false;
			}
		})

		const rulesList = isArray ? rulesInput : [rulesInput];
		let errInd = 0;
		rulesList.forEach((rules) => { // rules = {num: [{int_km: {title: 'Kms'}}, {int_mh: {title: 'MotoHours', empty: true}}]},
			errors[errInd] = {};
			const listRules = Object.keys(rules);
			listRules.forEach((rule) => { // rule = num или другая функция
				const fieldsList = Object.keys(rules[rule]);
				fieldsList.forEach((field) => { // fields = int_km  или int_mh
					const fieldSetting = rules[rule][field];
					if (fieldSetting.title) {
						titles[field] = fieldSetting.title;
					}
					if (fieldSetting.ref) {
						refs[field] = fieldSetting.ref;
					}
					const errorMessage = self[rule](vm, data ? data[field] : undefined, field, fieldSetting);
					if (errorMessage) {
						if (!errors[errInd][field]) {
							errors[errInd][field] = [];
						}
						errors[errInd][field].push(errorMessage);
					}
				})
			})
			errInd++;
		});
		let minErrorObj = [];
		let minErrorCount = Number.MAX_SAFE_INTEGER;
		let existWithoutErrorRule = false;

		errors.forEach((err) => {
			const fields = Object.keys(err);
			if (fields.length === 0) existWithoutErrorRule = true;
			let localCount = 0;
			fields.forEach((fieldErrors) => {
				localCount += err[fieldErrors].length;
			})
			if (minErrorCount > localCount) {
				minErrorObj = [err];
				minErrorCount = localCount;
			} else if (minErrorCount === localCount) {
				minErrorObj.push(err);
			}
		});
		if (existWithoutErrorRule) return {isValid: true};

		let errorMsg = '';
		minErrorObj.forEach((error) => {
			let localErrorMsg = '';
			const fields = Object.keys(error);
			fields.forEach((item) => {
				if (border && refs[item]) {
					const listRefsItems = refs[item].split(',');
					listRefsItems.forEach((refItem) => {
						if (vm.$refs[refItem]) {
							vm.$refs[refItem].$el.classList.toggle('red-border-and-shadow', true);
							setTimeout(() => {
								vm.$refs[refItem].$el.classList.toggle('red-border-and-shadow', false);
							}, 5000);
						}
					});
				}
				localErrorMsg += (titles[item] ? titles[item] : item) + ':' + '\n';
				error[item].forEach((msg, ind) => {
					localErrorMsg += (ind > 0 ? '- ' : '- ') + msg + '\n';
				})
			})
			if (errorMsg.indexOf(localErrorMsg) === -1) {
				errorMsg += (errorMsg !== '' ? 'ИЛИ' + '\n' : '');
				errorMsg += localErrorMsg;
			}
		});
		if (alert && errorMsg !== '') {
			vm.$alert.showErrorMessage(errorMsg, 15000);
		}

		return {isValid: false, detail: minErrorObj};
	},


	minLen(vm, data, field, params, message = 'Короткое значение') {
		if (((params.empty === undefined || params.empty) && !data) || (data && data.length < params.min)) {
			return message + (params.min ? ' [' + params.min + ']' : '');
		}
	},
	minMaxLength(vm, data, field, params, message = 'Длинна не в диапазоне') {
		if (((params.empty === undefined || params.empty) && !data) || (data && data.length < length)) {
			return message + ((params.min && params.max) ? ' [' + params.min + ',' + params.max + ']' : '');
		}
	},

	email(vm, data, field, params, message = 'Неверный email') {
		const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (data ? !reg.test(data) : (params.empty === undefined || !params.empty)) {
			return message;
		}
	},
	int(vm, data, field, params, message = 'Неверное значение') {
		const reg = /^-?[0-9]+$/;
		if (data ? !reg.test(data) : (params.empty === undefined || !params.empty)) {
			return message;
		}
	},
	float(vm, data, field, params, message = 'Неверное значение') {
		const reg = /^-?[0-9]+$/;
		if (data ? !reg.test(data) : (params.empty === undefined || !params.empty)) {
			return message;
		}
	},
	uint(vm, data, field, params, message = 'Неверное значение') {
		const reg = /^[0-9]+$/;
		if (data ? !reg.test(data) : (params.empty === undefined || !params.empty)) {
			return message;
		}
	},
	ufloat(vm, data, field, params, message = 'Неверное значение') {
		const reg = /^[0-9]+$/;
		if (data ? !reg.test(data) : (params.empty === undefined || !params.empty)) {
			return message;
		}
	},
	required(vm, data, field, params, message = 'Обязательное поле') {
		if (data ? (typeof data === 'object' && Object.keys(data).length === 0) : !(params.empty && data === '')) {
			return message;
		}
	},
	datetime(vm, data, field, params, message = 'Неверное значение') {
		const reg1 = /^[0-9]{1,2}.[0-9]{1,2}.[0-9]{4} [0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2}$/;
		const reg2 = /^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2} [0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2}$/;
		let format = true;
		if (data && params.format) {
			try {
				const objDateTime = moment(data);
				// eslint-disable-next-line no-underscore-dangle
				format = objDateTime._f === params.format;
			} catch (e) {
				format = false;
			}
		}
		if ((!params.empty && !format) || (data ? (params.format === undefined && !reg1.test(data) && !reg2.test(data)) : (params.empty === undefined || !params.empty))) {
			return message;
		}
	},
	time(vm, data, field, params, message = 'Неверное значение') {
		const reg = /^[0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2}$/;
		let format = true;
		if (data && params.format) {
			try {
				const objDateTime = moment(data);
				// eslint-disable-next-line no-underscore-dangle
				format = objDateTime._f === params.format;
			} catch (e) {
				format = false;
			}
		}
		if ((!params.empty && !format) || (data ? (params.format === undefined && !reg.test(data)) : (params.empty === undefined || !params.empty))) {
			return message;
		}
	},
	date(vm, data, field, params, message = 'Неверное значение') {
		const reg1 = /^[0-9]{1,2}.[0-9]{1,2}.[0-9]{4}$/;
		const reg2 = /^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/;
		let format = true;
		if (data && params.format) {
			try {
				const objDateTime = moment(data);
				// eslint-disable-next-line no-underscore-dangle
				format = objDateTime._f === params.format;
			} catch (e) {
				format = false;
			}
		}
		if ((!params.empty && !format) || (data ? (params.format === undefined && !reg1.test(data) && !reg2.test(data)) : (params.empty === undefined || !params.empty))) {
			return message;
		}
	},
	phone(vm, data, field, params, message = 'Неверное значение') {
		const reg1 = /^\+?[0-9\s]{11,64}$/;
		if (data ? !reg1.test(data) : (params.empty === undefined || !params.empty)) {
			return message;
		}
	},
}


/*
Пример настройки правил проверки формы. В примере приведенном ниже указаны 3 отдельных правила.
Выполнение ЛЮБОГО из них сделает форму валидной.
В каждом правиле указаны обязательные поля `name` и `ptn`.
Далее для каждого правила есть еще 1 обязательное (empty:false или не указано) и 2 необязательных поля (empty: true).

Пример формата минимального правила проверки является ли поле (fieldName) числом - {int : {fieldName:{}}}

Если правил несколько то формируется массив с перечислением правил [{int : {fieldName:{}}}, {int : {fieldName2:{}}}]
В данном примере достаточно что бы какое-то одно из полей (fieldName, fieldName2) было целым числом.

общая структура 1 правила - {<название функции проверки> : {<поле проверки>:{<различные параметры для проверки>}}}
К параметрам относится к премеру:
title - ключ поля перевода, который будет использован в this.$t()
ref - название ссылки, по которой будет подсвечиваться поле с ошибкой
empty - по умолчанию обрабатывается как false

Остальные параметры зависят от функций, которые их используют. К примеру
minLength - min
minMaxLength - min, max
date - format (если не указан - будет проверяться на YYYY-MM-DD и DD.MM.YYYY)
datetime - format  (если не указан - будет проверяться на YYYY-MM-DD HH:ii:SS и DD.MM.YYYY HH:ii:SS )
и т.д. в зависимости отдобавленных новых проверок

Параметры title и ref можно указать только 1 раз для поля, их дублирование в каждом правиле не требуется.
Если есть необходимость подсвечивать сразу несколько элементов по 1 правилу (например дата и время редактируются
в разных компонентах, а отпправляются в переменной) - то все ref можно перечислить через
запятую (ref: 'refToDate,refToTime')

const rules = [
				{
					minLen: {name: {title: 'nameTO', ref: 'nameto', min: 2}},
					float: {
						ptn: {title: 'percentToNotifications', ref: 'ptn'},
						int_km: {title: 'Kms', ref: 'kms',},
						int_mh: {title: 'MotoHours', ref: 'moto', empty: true},
						int_d: {title: 'AmountOfDays', ref: 'aod', empty: true},
					}
				},
				{
					minLen: {name: {min: 2}},
					float: {
						ptn: {},
						int_km: {empty: true},
						int_mh: {},
						int_d: {empty: true},
					}
				},
				{
					minLen: {name: {min: 2}},
					float: {
						ptn: {},
						int_km: {empty: true},
						int_mh: {empty: true},
						int_d: {},
					}
				},
			];

 */
